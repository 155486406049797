// import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";

// entzy config
import configEntzy from "components/config/ConfigEntzy";

// entzy components
// import { ActionLoader } from "components/utils/common/CommonLoaders";
import { UserAvatar } from "components/utils/common/CommonAvatars";

// fonts and icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faInfoCircle as iconInfo } from "@fortawesome/pro-thin-svg-icons";
// import { faChevronCircleLeft as iconSelectLeft } from "@fortawesome/pro-duotone-svg-icons";
// import { faChevronCircleRight as iconSelectRight } from "@fortawesome/pro-duotone-svg-icons";
// import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";

function HomeQrCode(props) {
  const user = props.user;
  // const mainContext = useContext(MainContext);

  // const [loading, setLoading] = useState(false);

  return (
    <Box className="box-default">
      {/* {loading && (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              height: configEntzy.AVATAR_CONTAINER_XL2X,
            }}
          >
            <ActionLoader />
          </Box>
        </Box>
      )} */}
      <Grow in={true} timeout={2000}>
        <Box className="box-default">
          <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
            <Typography variant="h1" color="white">
              Your QR Code
            </Typography>
            <Typography
              variant="h6"
              color="white"
              className="glowing-text"
              sx={{ mt: configEntzy.APP_SPACING_SM }}
            >
              For people to contact you
            </Typography>
          </Box>
          <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
            <Box
              className="box-inline bg-black"
              sx={{
                p: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
                color: "white",
              }}
            >
              <UserAvatar
                user={user}
                size="lg"
                nameHideInfo={true}
                avatarQrCode={true}
              />
            </Box>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}

export default HomeQrCode;
