// react core
import { Fragment, useContext, useState, useEffect } from "react";

// qr codes
import { QRCode } from "react-qrcode-logo";

// material design
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
// import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { SETTINGS_CONSTANTS } from "models/Settings";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird as iconWaiting } from "@fortawesome/pro-duotone-svg-icons";

export function UserAvatar(props) {
  // passed in state
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const user = props.user ? props.user : { identity: null };
  const identity = user.identity;
  const name = user.name;
  const size = props.size;
  const avatarOnly = props.avatarOnly;
  const avatarQrCode = props.avatarQrCode;
  const nameOnly = props.nameOnly;
  const nameHideInfo = props.nameHideInfo;
  const nameHideGuestInfo = props.nameHideGuestInfo;
  const imageOverride = props.imageOverride;

  // check for event context and identify if owner or manager
  let isEvent, isOwner, isManager, isRunner, isRider;
  if (eventContext) {
    isEvent = true;
    isOwner = eventContext.state.event.data.UserId === identity;
    // for managers need to check if the user identity is in the eventContext.runners.data.items array of objects based on UserId
    isManager = eventContext.state.runners.data.items.filter(
      (obj) => obj.UserId === identity
    ).length;
    isRunner = isOwner || isManager;
    isRider = !isOwner && !isManager;
  } else {
    isEvent = false;
    isOwner = false;
    isManager = false;
    isRunner = false;
    isRider = false;
  }

  // check for main context and identify if viewing disconnected
  const isGuestView =
    mainContext &&
    mainContext.state.user &&
    mainContext.state.user.identity === "guest:user"
      ? true
      : false;

  // local avatar state
  const [src, setSrc] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [spacing, setSpacing] = useState(configEntzy.APP_SPACING_XS);
  const [dimensions, setDimensions] = useState(configEntzy.AVATAR_SIZE_MD);
  const [pixelDimensions, setPixelDimensions] = useState(200);
  const [fontScale, setFontScale] = useState("3x");
  const [fontVariant, setFontVariant] = useState("subtitle2");
  const [avatarEmpty, setAvatarEmpty] = useState(
    user.avatar && user.avatar.key === configEntzy.EMPTY_STRING_SET
      ? true
      : false
  );
  const [avatarKey, setAvatarKey] = useState(
    user.avatar && user.avatar.key ? user.avatar.key : null
  );
  const [avatarImageSet] = useState(() => {
    return {
      lg: {
        success: false,
      },
      md: {
        success: false,
      },
      sm: {
        success: false,
      },
    };
  });
  const [avatarImages, setAvatarImages] = useState(
    user.avatar && user.avatar.images ? user.avatar.images : avatarImageSet
  );
  const [avatarName, setAvatarName] = useState(null);
  const [avatarHydrated, setAvatarHydrated] = useState(
    user.avatar && user.avatar.hydrated ? true : avatarEmpty ? true : false
  );
  const [avatarReady, setAvatarReady] = useState(false);
  const [userInteraction, setUserInteraction] = useState(null);

  const getUserHandles = (userName, avatarName) => {
    const displayName = userName
      ? "@" + userName
      : avatarName
      ? "@" + avatarName
      : "@member";
    const contactLink = configEntzy.APP_URL + "/" + displayName;
    const contactDomain = configEntzy.APP_DOMAIN + "/" + displayName;
    return { displayName, contactLink, contactDomain };
  };

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };
  const handleOpenDMs = () => {
    mainContext.setMemberMessageViewer({
      open: mainContext.state.memberMessaging.viewer.open ? false : true,
      user: userInteraction,
    });
  };

  const handleOpenContacts = () => {
    mainContext.setMemberContactViewer({
      open: mainContext.state.memberContact.viewer.open ? false : true,
      user: userInteraction,
    });
  };

  const handleOpenContactLink = () => {
    const userHandles = getUserHandles(user.name, avatarName);
    const contactLink = userHandles.contactLink;
    window.open(contactLink, "_blank", "noopener,noreferrer");
  };

  const [animate, setAnimate] = useState(0);

  // toggle animation on a 6 second delay for N times
  useEffect(() => {
    if (isGuestView) {
      const timer = setInterval(() => {
        setAnimate(animate + 1);
      }, 4000);
      if (animate > 21) {
        clearInterval(timer);
      }
      return () => clearInterval(timer);
    }
  }, [isGuestView, animate]);

  useEffect(() => {
    const createInteraction = async () => {
      const interaction =
        name && !identity
          ? mainContext.state.interaction.users.filter(
              (obj) => obj.name === name
            )
          : mainContext.state.interaction.users.filter(
              (obj) => obj.identity === identity
            );
      // resuse user details if cached
      if (interaction.length) {
        setAvatarKey(interaction[0].avatar.key);
        setAvatarImages(interaction[0].avatar.images);
        setAvatarName(interaction[0].name);
        setAvatarEmpty(
          interaction[0].avatar &&
            interaction[0].avatar.key === configEntzy.EMPTY_STRING_SET
            ? true
            : false
        );
        setAvatarHydrated(true);
        setUserInteraction(interaction[0]);
      } else {
        const userDetails = await mainContext.prepareInteractWithUser({
          identity,
          name,
        });
        if (userDetails.alert) {
          setAvatarHydrated(true);
        } else {
          mainContext.interactWithUser(userDetails);
          setAvatarKey(userDetails.data.avatar.key);
          setAvatarImages(userDetails.data.avatar.images);
          setAvatarName(userDetails.data.name);
          setAvatarEmpty(
            userDetails.avatar &&
              userDetails.avatar.key === configEntzy.EMPTY_STRING_SET
              ? true
              : false
          );
          setAvatarHydrated(true);
          setUserInteraction(userDetails.data);
        }
      }
    };
    // check if a prior interaction with this user exists
    if (mainContext) {
      createInteraction();
    }
  });

  useEffect(() => {
    const setAvatarProperties = async () => {
      switch (size) {
        case "xs":
          setSpacing(configEntzy.APP_SPACING_XS);
          setDimensions(configEntzy.AVATAR_SIZE_XS);
          setPixelDimensions(100);
          setFontScale("1x");
          setFontVariant("body2");
          !avatarEmpty && avatarImages.sm && avatarImages.sm.success
            ? setSrc(avatarImages.sm.data)
            : setSrc("");
          break;
        case "sm":
          setSpacing(configEntzy.APP_SPACING_XS2X);
          setDimensions(configEntzy.AVATAR_SIZE_SM);
          setPixelDimensions(150);
          setFontScale("1x");
          setFontVariant("body2");
          !avatarEmpty && avatarImages.sm && avatarImages.sm.success
            ? setSrc(avatarImages.sm.data)
            : setSrc("");
          break;
        case "md":
          setSpacing(configEntzy.APP_SPACING_XS3X);
          setDimensions(configEntzy.AVATAR_SIZE_MD);
          setPixelDimensions(200);
          setFontScale("2x");
          setFontVariant("subtitle2");
          !avatarEmpty && avatarImages.md && avatarImages.md.success
            ? setSrc(avatarImages.md.data)
            : setSrc("");
          break;
        case "lg":
          setSpacing(configEntzy.APP_SPACING_MD);
          setDimensions(configEntzy.AVATAR_SIZE_LG);
          setPixelDimensions(250);
          setFontScale("3x");
          setFontVariant("h4");
          !avatarEmpty && avatarImages.lg && avatarImages.lg.success
            ? setSrc(avatarImages.lg.data)
            : setSrc("");
          break;
        default:
          setSpacing(configEntzy.APP_SPACING_XS3X);
          setDimensions(configEntzy.AVATAR_SIZE_MD);
          setPixelDimensions(200);
          setFontScale("2x");
          setFontVariant("subtitle2");
          !avatarEmpty && avatarImages.md && avatarImages.md.success
            ? setSrc(avatarImages.md.data)
            : setSrc("");
          break;
      }
      setAvatarReady(true);
    };
    if (avatarHydrated) {
      setAvatarProperties();
    }
  }, [
    size,
    avatarHydrated,
    avatarEmpty,
    avatarKey,
    avatarImageSet,
    avatarImages,
  ]);

  function renderAvatar() {
    const userHandles = getUserHandles(user.name, avatarName);
    const contactLink = userHandles.contactLink;

    return (
      <Fragment>
        {avatarReady && !avatarQrCode && (
          <Avatar
            variant="rounded"
            className={
              identity
                ? "bg-black-t50 shadow-default rounded"
                : "bg-black-t10 shadow-default rounded"
            }
            src={imageOverride ? imageOverride : src}
            alt="Avatar"
            sx={{
              width: dimensions,
              height: dimensions,
              backgroundColor: "transparent",
            }}
          >
            <FontAwesomeIcon
              icon={SETTINGS_CONSTANTS.iconImageFallback}
              size={fontScale}
              color={identity ? "white" : "white"}
            />
          </Avatar>
        )}
        {avatarReady && avatarQrCode && (
          <Box
            className="box-default"
            sx={{
              width: pixelDimensions,
              height: pixelDimensions,
              backgroundColor: "transparent",
              borderRadius: configEntzy.BORDER_SIZE_LG,
              overflow: "hidden",
            }}
          >
            <QRCode
              value={contactLink}
              ecLevel="Q"
              size={pixelDimensions - 20}
              bgColor="white"
              fgColor="purple"
              quietZone={10}
              logoImage={src}
              logoWidth={pixelDimensions / 4}
              logoHeight={pixelDimensions / 4}
              logoOpacity={0.8}
              logoPadding={5}
              logoPaddingStyle="square"
              qrStyle="dots"
              eyeRadius={10}
              removeQrCodeBehindLogo={true}
            />
          </Box>
        )}
      </Fragment>
    );
  }

  function renderName() {
    const isUser =
      (mainContext.state.user &&
        mainContext.state.user.identity === identity) ||
      (userInteraction && userInteraction.self);
    const userHandles = getUserHandles(user.name, avatarName);
    const displayName = userHandles.displayName;
    return (
      <Box className="box-default">
        {avatarQrCode && (
          <Typography variant={fontVariant} className="lower-case">
            &nbsp;{configEntzy.APP_DOMAIN}&nbsp;
          </Typography>
        )}
        {displayName && (
          <Typography variant={fontVariant} className="lower-case">
            &nbsp;{displayName}&nbsp;&nbsp;
          </Typography>
        )}
        {isGuestView && !nameHideGuestInfo && (
          <Box className="box-default">
            <Grow in={animate % 2 === 0} timeout={2000}>
              <Box className="box-default zero-height">
                <Typography variant={fontVariant} color="primary">
                  To see members
                </Typography>
              </Box>
            </Grow>
            <Grow in={animate % 2 !== 0} timeout={2000}>
              <Box className="box-default zero-height">
                <Typography variant={fontVariant} color="primary">
                  Connect
                </Typography>
              </Box>
            </Grow>
            <Box className="box-default">
              <Typography variant={fontVariant}>&nbsp;</Typography>
            </Box>
          </Box>
        )}
        {!nameHideInfo && (
          <Fragment>
            <Typography variant={fontVariant}>
              <span style={{ opacity: 0.6 }}>
                &nbsp;
                {isEvent && isOwner
                  ? "Runner: Owner"
                  : isEvent && isManager
                  ? "Runner: Manager"
                  : isEvent && isRunner
                  ? "Runner"
                  : isEvent && isRider
                  ? "Rider"
                  : "Member"}
                &nbsp;
              </span>
            </Typography>
            {!isGuestView && (
              <Typography variant={fontVariant} color="white">
                {isUser ? (
                  <span
                    className="bg-black-t50 bdp2 rounded"
                    style={{
                      fontWeight: 200,
                    }}
                  >
                    <span>&nbsp;&nbsp;</span>
                    <span></span>You
                    <span>&nbsp;&nbsp;</span>
                  </span>
                ) : userInteraction && userInteraction.contact ? (
                  <span
                    className="bg-black-t50 bdp2 rounded"
                    style={{
                      fontWeight: 200,
                    }}
                  >
                    <span>&nbsp;&nbsp;</span>
                    In Contacts
                    <span>&nbsp;&nbsp;</span>
                  </span>
                ) : (
                  <span
                    className="bg-black-t50 bdp2 rounded action-pointer"
                    style={{
                      fontWeight: 200,
                    }}
                    onClick={handleOpenContacts}
                  >
                    <span>&nbsp;&nbsp;</span>
                    <span className="content-disabled">+ Add Contact</span>
                    <span>&nbsp;&nbsp;</span>
                  </span>
                )}
              </Typography>
            )}
          </Fragment>
        )}
      </Box>
    );
  }

  if (avatarOnly) {
    return renderAvatar();
  } else if (nameOnly) {
    return renderName();
  } else {
    return (
      <Box
        className={
          "box-default" +
          (props.shadow ? " bg-white-t50 shadow-default rounded" : "")
        }
        sx={{
          width: avatarQrCode ? pixelDimensions : dimensions,
          minHeight: avatarQrCode ? pixelDimensions : dimensions,
        }}
      >
        {avatarReady && (
          <Box className="box-default">
            <Box
              className={
                "box-default" + (props.onClick ? " action-pointer" : "")
              }
              onClick={
                props.onClick
                  ? props.onClick
                  : avatarQrCode
                  ? handleOpenContactLink
                  : props.hiddenName
                  ? handleShowDetails
                  : handleOpenDMs
              }
            >
              {renderAvatar()}
            </Box>
            <Box
              className="box-default"
              hidden={props.hiddenName ? !showDetails : false}
            >
              <Box
                className={
                  "box-default center" +
                  (props.onClick ? " action-pointer" : "")
                }
                onClick={props.onClick}
                sx={{
                  mt: spacing,
                  color: props.textLight
                    ? "white"
                    : props.textDark
                    ? "black"
                    : undefined,
                }}
              >
                <Box
                  className="box-shift-center"
                  sx={{
                    p: configEntzy.APP_SPACING_SM,
                  }}
                >
                  {renderName()}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

export function CounterAvatar(props) {
  const [size] = useState(props.size);
  const [dimensions, setDimensions] = useState(configEntzy.AVATAR_SIZE_MD);
  const [avatarReady, setAvatarReady] = useState(false);

  useEffect(() => {
    const setAvatarProperties = async () => {
      switch (size) {
        case "xs":
          setDimensions(configEntzy.AVATAR_SIZE_XS);
          break;
        case "sm":
          setDimensions(configEntzy.AVATAR_SIZE_SM);
          break;
        case "md":
          setDimensions(configEntzy.AVATAR_SIZE_MD);
          break;
        case "lg":
          setDimensions(configEntzy.AVATAR_SIZE_LG);
          break;
        default:
          setDimensions(configEntzy.AVATAR_SIZE_MD);
          break;
      }
      setAvatarReady(true);
    };
    setAvatarProperties();
  }, [size]);
  return (
    <Box className="box-default">
      {avatarReady && (
        <Box className="box-default">
          <Box className="box-inline">
            <Avatar
              variant="circle"
              className="shadow-default"
              alt="Avatar"
              sx={{
                backgroundColor: props.dark
                  ? "secondary.main"
                  : props.highlight
                  ? "highlight.light"
                  : "white",
                color: props.dark ? "white" : "black",
                width: dimensions,
                height: dimensions,
                overflow: "visible",
              }}
            >
              <Typography
                variant="h6"
                className="upper-case"
                sx={{ overflow: "visible" }}
              >
                {props.waiting ? (
                  <span className="fa-layers">
                    <FontAwesomeIcon
                      icon={iconWaiting}
                      color="orange"
                      spin={true}
                      transform="grow-40"
                    />
                    <span className="fa-layers-text">{props.count}</span>
                  </span>
                ) : (
                  <span>{props.count}</span>
                )}
              </Typography>
            </Avatar>
          </Box>
        </Box>
      )}
    </Box>
  );
}
